import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { action } from 'mobx'
import { useStore } from '~/src/app/store'
import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useSelectedStep } from '~/src/hooks/training'
import MultiuserPanel from '~src/features/multiuser/components/MultiuserPanel'
import CustomIcon from '~/src/app/components/Icon'
import rootStore from '../../store'
import PlayerMenu from './PlayerMenu'
import PlayerTextInstructions from '~src/features/stepInstructions/PlayerTextInstructions'
import PlayerAudioInstructions from '~src/features/stepInstructions/PlayerAudioInstructions'
import logo from '~assets/images/aucta-logo.svg'

const PlayerUI = ({ hideBackButton }) => {
  const { appState, player } = useStore()
  const selectedStep = useSelectedStep()
  // handlers
  const onGoBack = action(() => appState.editorMode())
  const onNextStepButton = action(() => player.manuallyNavigateToNextStep())
  const onPrevStepButton = action(() => player.manuallyNavigateToPrevStep())
  const { idx, total } = player.getCurrentStepInfo()
  // initialize the player
  useEffect(() => {
    player.start()
  }, [])

  return (
    <div className="player-ui-container">
      <div className="top-bar">
        <a className="player-logo" href="https://aucta.io/demo/">
          <img src={logo} />
        </a>
        {!hideBackButton && (
          <div className="go-back-button-container">
            <Button
              onClick={onGoBack}
              className="go-back-button top-button"
              icon="chevron-left"
              outlined
              large
            />
          </div>
        )}

        {appState.fullscreen && (
          <div className="dropdown-menu">
            <Button
              className="dropdown-menu-button top-button"
              icon="cross"
              outlined
              large
              onClick={() => appState.disableFullscreen()}
            />
          </div>
        )}

        {/* {!appState.fullscreen && <div className="dropdown-menu"> */}
        {/*   <Button className="dropdown-menu-button big-button" icon="fullscreen" outlined large */}
        {/*     onClick={() => appState.enableFullscreen()} /> */}
        {/* </div>} */}

        {!appState.fullscreen && (
          <Popover2
            content={<PlayerMenu />}
            placement="bottom-end"
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <div className="dropdown-menu">
                <Button
                  {...targetProps}
                  elementRef={ref}
                  className="dropdown-menu-button top-button"
                  icon="more"
                  large
                />
              </div>
            )}
          ></Popover2>
        )}
      </div>

      {!player.disabledFeatures.multiuser && (
        <MultiuserPanel
          ready={rootStore.multiuser.ready}
          users={rootStore.multiuser.usersNum}
          moderator={rootStore.multiuser.moderator}
          isModerator={rootStore.multiuser.isModerator}
          roomToken={rootStore.multiuser.roomToken}
          moderationStart={rootStore.multiuser.moderationStart}
          onModeration={() => rootStore.multiuser.startModeration()}
          onStopModeration={() => rootStore.multiuser.stopModeration()}
        />
      )}

      <div className="player-controls">
        <div className="progress-info">
          {total > 0 && idx >= 0 ? `${idx + 1} of ${total}` : '...'}
        </div>
        <div className="button-forward">
          <Button
            onClick={onNextStepButton}
            disabled={!player.isForwardNavigationPossible()}
            className="big-button"
            large
            outlined
            icon={<CustomIcon icon="arrowRight" />}
          />
        </div>
        <div className="button-back">
          <Button
            onClick={onPrevStepButton}
            disabled={!player.isBackNavigationPossible()}
            className="big-button"
            large
            icon={<CustomIcon icon="arrowLeft" />}
          />
        </div>
      </div>

      <div className="player-instructions-container">
        <div className="progress-info show-mobile">
          {total > 0 && idx >= 0 ? `${idx + 1} of ${total}` : '...'}
        </div>
        <PlayerTextInstructions step={selectedStep} />
        <PlayerAudioInstructions step={selectedStep} />
      </div>
    </div>
  )
}

export default observer(PlayerUI)
