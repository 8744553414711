import {
  EasingFunction,
  QuadraticEase,
} from '@babylonjs/core/Animations/easing'
import { Vector3 } from '@babylonjs/core/Maths/math'
import { Animation } from '@babylonjs/core/Animations/animation'
import { Animation } from '@babylonjs/core/Animations/animation'
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents'
import { createPromise } from '~/src/utils/general'
import { assign, clamp } from 'lodash'

const DEFAULT_DURATION = 2000

class CameraStore {
  currentTransition = null
  transitionAnimation = null
  onFinished = null
  running = false
  promise = null

  constructor({ ui, sceneManager }) {
    assign(this, { ui, sceneManager })
    document.addEventListener('wheel', this.adjustPanningSpeed)
  }

  setTarget(target) {
    const { camera } = this.sceneManager
    this.startTransition(camera.position.clone(), target, 500)
  }

  setPose({ target, position }) {
    if (!target || !position) return
    console.log('~~~~~', target, position)
    const { camera } = this.sceneManager
    console.log(target, camera)
    camera.setPosition(Vector3.FromArray(position))
    camera.setTarget(Vector3.FromArray(target))
  }

  startTransition(position, target, duration = DEFAULT_DURATION, onFinished) {
    if (!target || !position) return
    this.stopTransition()
    this.promise = createPromise()
    const { camera, scene } = this.sceneManager
    const FPS = 120
    const frames = Math.round((duration / 1000) * FPS)
    const currentTarget = this.getTarget()
    const currentPosition = this.getPosition()
    this.currentTransition = {
      position: Animation.CreateAndStartAnimation(
        'camera.position',
        camera,
        'position',
        FPS,
        frames,
        currentPosition,
        position,
        0,
      ),
      target: Animation.CreateAndStartAnimation(
        'camera.target',
        camera,
        'target',
        FPS,
        frames,
        currentTarget,
        target,
        0,
      ),
    }
    this.setEasingFunction(this.currentTransition.position)
    this.setEasingFunction(this.currentTransition.target)
    this.currentTransition.position.onAnimationEnd = this.onAnimationEnd
    scene.onPointerObservable.add(this.handleEvents)
    this.onFinished = onFinished
    this.running = true
    return this.promise
  }

  onAnimationEnd = async () => {
    this.sceneManager.scene.onPointerObservable.removeCallback(
      this.handleEvents,
    )
    if (this.onFinished) await this.onFinished()
    if (this.promise) this.promise.resolve()
    this.running = false
    this.onFinished = null
    this.promise = null
  }

  stopTransition = async () => {
    if (!this.running) return
    if (this.onFinished) await this.onFinished()
    if (this.promise) this.promise.resolve()
    this.running = false
    this.currentTransition.position.stop()
    this.currentTransition.target.stop()
  }

  handleEvents = ({ type }) => {
    if (type === PointerEventTypes.POINTERDOWN) this.stopTransition()
  }

  adjustPanningSpeed = _wheelEvent => {
    const { camera } = this.sceneManager
    //These are the curve function values that worked best
    camera.panningSensibility = 10000 / (camera.radius + 0.1) + 600
    camera.panningSensibility = clamp(camera.panningSensibility, 1000, 30000)
    camera.panningInertia = 0.75 + camera.radius / 10
    camera.panningInertia = clamp(camera.panningInertia, 0.75, 0.85)
  }

  setEasingFunction(animatable) {
    const animation = animatable.getAnimations()[0].animation
    // const easing = new SineEase()
    // const easing = new CubicEase()
    const easing = new QuadraticEase()
    easing.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)
    animation.setEasingFunction(easing)
  }

  getPosition() {
    const { camera } = this.sceneManager
    return camera.position.clone()
  }

  getTarget() {
    const { camera } = this.sceneManager
    return camera.getTarget().clone()
  }

  getPose() {
    return {
      target: this.getTarget().asArray(),
      position: this.getPosition().asArray(),
    }
  }
}

export default CameraStore
