import { useRef, useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import { get } from 'lodash'
import { useStore } from '~/src/app/store'
import CustomIcon from '~/src/app/components/Icon'

const PlayerAudioInstructions = ({ step = {} }) => {
  const { persistence } = useStore()
  const audioFile = get(step, ['narration', 'key'], null)
  const audio = useRef()
  const [isPlaying, setPlaying] = useState(false)
  const handleStop = () => {
    if (!audio.current) return
    audio.current.pause()
  }
  const handlePlay = () => {
    if (!audio.current) return
    audio.current.currentTime = 0
    audio.current.play()
  }
  useEffect(() => {
    if (audio.current) {
      audio.current.addEventListener('play', () => setPlaying(true))
      audio.current.addEventListener('pause', () => setPlaying(false))
    }
    return () => {
      audio.current && audio.current.stop && audio.current.stop()
    }
  }, [step.narration])
  return (
    <>
      {step.narration && (
        <div className="player-audio-instructions">
          <audio
            autoPlay
            src={persistence.getContentUrl(audioFile)}
            ref={audio}
          />
          {!isPlaying && (
            <Button
              outlined
              onClick={handlePlay}
              large
              className="big-button"
              icon={<CustomIcon icon="play" />}
            />
          )}
          {isPlaying && (
            <Button
              outlined
              onClick={handleStop}
              large
              className="big-button audio-button"
              icon={<CustomIcon icon="audio" />}
            />
          )}
        </div>
      )}
    </>
  )
}

export default PlayerAudioInstructions
